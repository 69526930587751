import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";

export default function LeadTimeToChangeCard(props) {
  const { data, dataKey, title } = props;

  let latestValue = 0;
  let deltaValue = 0;
  let deltaString = "";
  let delta = 0;
  let deltaIcon = faAngleDown;
  let deltaCSS = "text-success";
  let lineColor = "#3ac47d";
  if (data && data.length > 0) {
    latestValue = data[data.length - 1][dataKey];
    deltaValue =
      -1 *
      ((data.length > 2 ? data[data.length - 2][dataKey] : 0) - latestValue);
    deltaIcon = deltaValue < -1 ? faAngleUp : faAngleDown;
    deltaCSS = deltaValue < -1 ? "text-success" : "text-danger";
    lineColor = deltaValue < -1 ? "#3ac47d" : "red";
  }
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">Lead Time To Change</div>
        <div className="widget-numbers">{latestValue} Days</div>
        <div className="widget-subheading">{title} Start to Resolved</div>
        <div className={`widget-description ${deltaCSS}`}>
          <FontAwesomeIcon icon={deltaIcon} />
          <span className="ps-1">{deltaValue} days</span>
        </div>
      </div>
      <div className="widget-chart-wrapper chart-wrapper-relative">
        <ResponsiveContainer height={100}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 15, left: 15, bottom: 0 }}
          >
            <Line
              type="monotone"
              dataKey={dataKey}
              stroke={lineColor}
              strokeWidth={3}
            />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
