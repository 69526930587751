import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Legend } from "chart.js";
import { Col, Row } from "react-bootstrap";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

export default function ReleaseDefectCard(props) {
  const { title, data, legacyKey, mediumKey, releaseKey } = props;

  let releaseDefectPercentage = 0;
  let legacyCount = 0;
  let mediumCount = 0;
  let releaseCount = 0;
  if (data && data.length > 0) {
    const latestValue = data[data.length - 1];
    legacyCount = latestValue[legacyKey];
    mediumCount = latestValue[mediumKey];
    releaseCount = latestValue[releaseKey];
    releaseDefectPercentage = (
      (releaseCount / (legacyCount + mediumCount + releaseCount)) *
      100
    ).toFixed(2);
  }

  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">Release Defect Percentage</div>
        <div className="widget-numbers">{releaseDefectPercentage}%</div>
        <div className="widget-subheading">{title}</div>
        {/*<div className={`widget-description ${deltaCSS}`}>*/}
        {/*    <FontAwesomeIcon icon={deltaIcon} />*/}
        {/*    <span className="ps-1">{deltaValue} days</span>*/}
        {/*</div>*/}
      </div>
      <div className="widget-chart-wrapper chart-wrapper-relative">
        <ResponsiveContainer height={150}>
          <BarChart
            data={data}
            margin={{ top: 5, right: 15, left: 15, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />

            <Bar
              name="Legacy"
              dataKey={legacyKey}
              fill="#8884d8"
              label={{ fill: "black", fontSize: 14 }}
              strokeWidth={3}
            />
            <Bar
              name="Medium"
              dataKey={mediumKey}
              fill="#82ca9d"
              label={{ fill: "black", fontSize: 14 }}
              strokeWidth={3}
            />
            <Bar
              name="Release Defect"
              dataKey={releaseKey}
              fill="red"
              label={{ fill: "black", fontSize: 14 }}
              strokeWidth={3}
            />
          </BarChart>
        </ResponsiveContainer>
        <Row>
          <Col>
            <div style={{ color: "#8884d8" }}>
              <FontAwesomeIcon icon={faSquare} />
              &nbsp; Legacy (6+ Months)
            </div>
          </Col>
          <Col>
            <div style={{ color: "#82ca9d" }}>
              <FontAwesomeIcon icon={faSquare} />
              &nbsp; Medium (1-5 Months)
            </div>
          </Col>
          <Col>
            <div style={{ color: "red" }}>
              <FontAwesomeIcon icon={faSquare} />
              &nbsp; Release ( &#x3c; 30 Days)
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
