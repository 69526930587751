import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Line,
  ResponsiveContainer,
} from "recharts";

export default function EscalationsHistoryCard(props) {
  const { data, title } = props;

  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">{title}</div>
        <br />
        <div className="widget-chart-wrapper chart-wrapper-relative">
          <ResponsiveContainer height={300}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 5, left: 5, bottom: 0 }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="bottom" height={36} align="left" />
              <CartesianGrid strokeDasharray="3 3" />
              <Line
                name="Created"
                type="monotone"
                dataKey="created"
                stroke="red"
                strokeWidth={3}
              />
              <Line
                name="Resolved"
                type="monotone"
                dataKey="resolved"
                stroke="#3ac47d"
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
