import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import EscalationsPerEngCard from "./cards/EscalationsPerEngCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function EscalationsPerEngSection() {
  const { data } = useSelector((state) => state.metrics);

  let dposEngineers = 0;
  let registerEngineers = 0;
  let fintechEngineers = 0;
  let growthEngineers = 0;
  let escalationsData = [];
  if (data !== undefined && data.escalationsPerEng) {
    dposEngineers = data.escalationsPerEng.dposEngineers;
    registerEngineers = data.escalationsPerEng.registerEngineers;
    fintechEngineers = data.escalationsPerEng.fintechEngineers;
    growthEngineers = data.escalationsPerEng.growthEngineers;
    escalationsData = data.escalationsPerEng.data;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Escalations Per Engineer"}
        subtitle={
          "Tracking of created/resolved escalations per Engineer for a given charter"
        }
        target={
          "Number created per Eng is less than the number resolved by each Eng by a 2 to 1 margin."
        }
      />
      <Row>
        <Col md={6}>
          <EscalationsPerEngCard
            data={escalationsData}
            title="Core Backoffice"
            createdKey={"dposCreated"}
            resolvedKey={"dposResolved"}
            engCount={dposEngineers}
          />
        </Col>
        <Col md={6}>
          <EscalationsPerEngCard
            data={escalationsData}
            title="Register"
            createdKey={"registerCreated"}
            resolvedKey={"registerResolved"}
            engCount={registerEngineers}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <EscalationsPerEngCard
            data={escalationsData}
            title="Fintech"
            createdKey={"fintechCreated"}
            resolvedKey={"fintechResolved"}
            engCount={fintechEngineers}
          />
        </Col>
        <Col md={6}>
          <EscalationsPerEngCard
            data={escalationsData}
            title="Growth"
            createdKey={"growthCreated"}
            resolvedKey={"growthResolved"}
            engCount={growthEngineers}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
