import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_LOADING = "LOADING";
export const STATUS_LOADED = "LOADED";
export const STATUS_LOADING_ERROR = "LOADING_ERROR";

export const DEFAULT_FILE_LOAD = "../metricsFiles/metrics_sprint_2023_11.json";
export const fetchMetrics = createAsyncThunk(
  "metrics/fetchMetrics",
  async () => {
    try {
      const json = await axios.get(DEFAULT_FILE_LOAD);
      return json;
    } catch (e) {
      console.error("Error on file load");
    }
  }
);

const metricsSlice = createSlice({
  name: "metrics",
  initialState: {
    data: "Frank from Redux",
    status: STATUS_NOT_LOADED,
    loadedFile: DEFAULT_FILE_LOAD,
  },
  reducers: {
    metricsLoaded: (state, action) => {
      state.id = action.payload.id;
      state.data = action.payload.data;
      state.status = STATUS_LOADED;
    },
    metricsLoading: (state, action) => {
      state.status = STATUS_LOADING;
    },
    metricsLoadingError: (state, action) => {
      state.status = STATUS_LOADING_ERROR;
    },
  },
  extraReducers: {
    [fetchMetrics.pending]: (state) => {
      state.status = STATUS_LOADING;
    },
    [fetchMetrics.fulfilled]: (state, { payload }) => {
      state.status = STATUS_LOADED;
      state.data = payload.data;
    },
    [fetchMetrics.rejected]: (state) => {
      state.status = STATUS_LOADING_ERROR;
    },
  },
});

const { actions, reducer } = metricsSlice;
export const { metricsLoading, metricsLoaded, metricsLoadingError } = actions;
export default reducer;
