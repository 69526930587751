import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import EscalationsPerEngCard from "./cards/EscalationsPerEngCard";
import FlowDistributionCard from "./cards/FlowDistributionCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function FlowDistributionSection() {
  const { data } = useSelector((state) => state.metrics);

  let dposData = [];
  let registerData = [];
  let gbData = [];
  let fintechData = [];
  let growthData = [];
  if (data !== undefined && data.flowDistribution) {
    dposData = data.flowDistribution.dposData;
    registerData = data.flowDistribution.registerData;
    gbData = data.flowDistribution.gbData;
    fintechData = data.flowDistribution.fintechData;
    growthData = data.flowDistribution.growthData;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Flow Distribution"}
        subtitle={"Breakdown of issue types from the sprint by story points."}
        target={"Will vary by the current priorities of the charter."}
      />
      <Row>
        <Col md={4}>
          <FlowDistributionCard
            title={"DPOS Flow Distribution"}
            data={dposData}
          />
        </Col>
        <Col md={4}>
          <FlowDistributionCard
            title={"Register Flow Distribution"}
            data={registerData}
          />
        </Col>
        <Col md={4}>
          <FlowDistributionCard
            title={"Greenbits Flow Distribution"}
            data={gbData}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FlowDistributionCard
            title={"Fintech Flow Distribution"}
            data={fintechData}
          />
        </Col>
        <Col md={6}>
          <FlowDistributionCard
            title={"Growth Flow Distribution"}
            data={growthData}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
