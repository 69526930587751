import { Col, Row } from "react-bootstrap";
import NPSCard from "./cards/NPSCard";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function NPSMetricSection() {
  const { data } = useSelector((state) => state.metrics);

  let npsData = [];
  if (data !== undefined && data.nps) {
    npsData = data.nps.data;
  }

  return (
    <Fragment>
      {" "}
      <SectionHeader
        title={"Customer NPS"}
        subtitle={"Pendo numbers on a weekly basis."}
        target={"A positive NPS is the min goal."}
      />
      <Row>
        <Col md={4}>
          <NPSCard data={npsData} title={"Backoffice NPS"} dataKey={"dpos"} />
        </Col>
        <Col md={4}>
          <NPSCard
            data={npsData}
            title={"Greenbits NPS"}
            dataKey={"greenbits"}
          />
        </Col>
        <Col md={4}>
          <NPSCard data={npsData} title={"Growth NPS"} dataKey={"growth"} />
        </Col>
      </Row>
    </Fragment>
  );
}
