import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";
import EndToEndGraphCard from "./cards/EndToEndGraphCard";

export default function EndToEndTestsSection() {
  const { data } = useSelector((state) => state.metrics);

  let e2eDataTop5 = [];
  let e2eDataTotal = [];
  let e2eDataTop5Percentage = [];
  let e2eDataTotalPercentage = [];
  if (data !== undefined && data.e2e) {
    e2eDataTop5 = data.e2e.data.top5;
    e2eDataTotal = data.e2e.data.totale2e;
    e2eDataTop5Percentage = data.e2e.data.top5Percentage;
    e2eDataTotalPercentage = data.e2e.data.totale2ePercentage;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"E2E Automation Metrics"}
        subtitle={"Tracking of automation testing"}
      />
      <Row>
        <Col>
          <EndToEndGraphCard title={"E2E Top5 Flows"} data={e2eDataTop5} />
        </Col>
        <Col>
          <EndToEndGraphCard
            title={"E2E Total Test Cases"}
            data={e2eDataTotal}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EndToEndGraphCard
            title={"E2E Top5 Flows By Percentage"}
            data={e2eDataTop5Percentage}
          />
        </Col>
        <Col>
          <EndToEndGraphCard
            title={"E2E Total Test Cases by Percentage"}
            data={e2eDataTotalPercentage}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
