import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import IncidentsCard from "./cards/IncidentsCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function IncidentsSection() {
  const { data } = useSelector((state) => state.metrics);

  let incidentsData = [];
  if (data !== undefined && data.incidents) {
    incidentsData = data.incidents.data;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Incidents and Recovery"}
        subtitle={
          "MTTM: Mean time to mitigation, MTTR: Mean time to resolution"
        }
        target={
          "MTTM should be less than one hour, lower number of incidents in general"
        }
      />
      <Row>
        <Col>
          <IncidentsCard
            title={"DPOS"}
            mttmKey={"dposMTTM"}
            mttrKey={"dposMTTR"}
            incidentsKey={"dposIncidents"}
            data={incidentsData}
          />
        </Col>
        <Col>
          <IncidentsCard
            title={"Greenbits"}
            mttmKey={"gbMTTM"}
            mttrKey={"gbMTTR"}
            incidentsKey={"gbIncidents"}
            data={incidentsData}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <IncidentsCard
            title={"Payments"}
            mttmKey={"fintechMTTM"}
            mttrKey={"fintechMTTR"}
            incidentsKey={"fintechIncidents"}
            data={incidentsData}
          />
        </Col>
        <Col>
          <IncidentsCard
            title={"Dutchie"}
            mttmKey={"growthMTTM"}
            mttrKey={"growthMTTR"}
            incidentsKey={"growthIncidents"}
            data={incidentsData}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
