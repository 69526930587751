import { useSelector, useDispatch } from "react-redux";
import metricsSlice, {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOADING_ERROR,
  STATUS_NOT_LOADED,
  metricsLoading,
  fetchMetrics,
} from "./reducers/metricsSlice";
import { useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Stack } from "react-bootstrap";
import { Line, LineChart, ResponsiveContainer } from "recharts";

export default function TestPage1() {
  const { status } = useSelector((state) => state.metrics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMetrics());
  }, []);

  let message = "";
  if (status === STATUS_LOADED) {
    message = "Loaded";
  } else if (status === STATUS_LOADING) {
    message = "Loading";
  } else if (status === STATUS_LOADING_ERROR) {
    message = "Error Loading";
  } else {
    message = "Not Loaded Yet";
  }

  const test = async () => {
    try {
      const json = await axios.get(
        "../metricsFiles/metrics_sprint_2023_03.json"
      );
      console.log(json);
    } catch (e) {
      console.error("Error on file load");
    }
  };

  const weeklyEscalationsPerEng = [
    {
      name: "Escalations 1/15/23",
      dposCreated: 296,
      dposResolved: 130,
      registerCreated: 161,
      registerResolved: 49,
    },
    {
      name: "Escalations 1/31/23",
      dposCreated: 209,
      dposResolved: 122,
      registerCreated: 77,
      registerResolved: 51,
    },
  ];

  return (
    <div>
      frank Test Page 1 - {message}{" "}
      <FontAwesomeIcon icon={faCoffee} size="xs" />
      <Card>
        <Stack direction="horizontal" gap={2}>
          <Button as="a" variant="primary">
            Button as link
          </Button>
          <Button as="a" variant="success">
            Button as link
          </Button>
        </Stack>
      </Card>
      <div className="card mb-3 widget-chart">
        <div className="widget-chart-content">
          <div className="widget-heading">
            Escalations per Engineer per Week
          </div>
          <div className="widget-numbers">2.18 created, 1.27 resolved</div>
          <div className="widget-subheading">Core Backoffice</div>
        </div>

        <ResponsiveContainer height={100}>
          <LineChart
            data={weeklyEscalationsPerEng}
            margin={{ top: 5, right: 5, left: 5, bottom: 0 }}
          >
            <Line
              type="monotone"
              dataKey="dposCreated"
              stroke="red"
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="dposResolved"
              stroke="#3ac47d"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
