import { Row, Col } from "react-bootstrap";

export default function SectionHeader(props) {
  const { title, subtitle, target } = props;
  return (
    <div style={{ padding: 20 }}>
      <h3>{title}</h3>
      <p>{subtitle}</p>
      {targetSection(target)}
    </div>
  );
}

function targetSection(target) {
  if (target) {
    return (
      <div className="section-header-target-container">
        <Row>
          <Col>
            <i className="pe-7s-target section-header-target " />
            Target: {target}
          </Col>
        </Row>
      </div>
    );
  }
  return <div></div>;
}
