import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TestPage1 from "./TestPage1";
import TestPage2 from "./TestPage2";
import JirlinDashboard from "./JirlinDashboard";

const router = createBrowserRouter([
  {
    path: "testPage1",
    element: <TestPage1 />,
  },
  {
    path: "testPage2",
    element: <TestPage2 />,
  },
  {
    path: "/",
    element: <JirlinDashboard />,
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
