import { Col, Row } from "react-bootstrap";

export default function StatCard(props) {
  const { title, value } = props;
  return (
    <div className={"stat-card"}>
      <h3 className={"widget-numbers"}>{value}</h3>
      <div>{title}</div>
    </div>
  );
}
