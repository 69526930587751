export default function DeploymentCard(props) {
  const { data, plannedKey, unplannedKey, title } = props;

  let deploymentPercentage = 0;
  let planned = 0;
  let unplanned = 0;
  let iconColor = "text-success";
  let iconBackgroundColor = "bg-success";
  if (data && data.length > 0) {
    const currentValues = data[data.length - 1];
    planned = currentValues[plannedKey];
    unplanned = currentValues[unplannedKey];
    deploymentPercentage =
      unplanned === 0 ? 100 : Math.round((planned / unplanned) * 100);
    iconColor = deploymentPercentage < 50 ? "text-danger" : "text-success";
    iconBackgroundColor =
      deploymentPercentage < 50 ? "bg-danger" : "bg-success";
  }
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="icon-wrapper rounded-circle">
          <div className={"icon-wrapper-bg " + iconBackgroundColor} />
          <i className={"lnr-rocket " + iconColor} />
        </div>
        <div className="widget-numbers">{deploymentPercentage}%</div>
        <div className="widget-subheading">{title}</div>
        <div className="widget-heading">Deployment Frequency</div>
        <div className={"widget-description " + iconColor}>
          <span className="ps-2">
            ({planned} planned, {unplanned} unplanned)
          </span>
        </div>
      </div>
    </div>
  );
}
