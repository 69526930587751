import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import DeploymentCard from "./cards/DeploymentsCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function DeploymentFrequencySection() {
  const { data } = useSelector((state) => state.metrics);
  let deploymentsData = [];
  if (data !== undefined && data.deploymentFrequency) {
    deploymentsData = data.deploymentFrequency.data;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Deployments Frequency"}
        subtitle={
          "Percentage describes planned vs unplanned releases. Unplanned releases are hotfixes reacting to either release defects or incidents."
        }
        target={"Percentage should be greater than 50%"}
      />
      <Row>
        <Col>
          <DeploymentCard
            title={"DPOS UI"}
            data={deploymentsData}
            plannedKey={"dposUIPlanned"}
            unplannedKey={"dposUIUnplanned"}
          />
        </Col>
        <Col>
          <DeploymentCard
            title={"DPOS API/DB"}
            data={deploymentsData}
            plannedKey={"dposAPIPlanned"}
            unplannedKey={"dposAPIUnplanned"}
          />
        </Col>
        <Col>
          <DeploymentCard
            title={"Greenbits"}
            data={deploymentsData}
            plannedKey={"greenbitsPlanned"}
            unplannedKey={"greenbitsUnplanned"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DeploymentCard
            title={"Fintech"}
            data={deploymentsData}
            plannedKey={"fintechPlanned"}
            unplannedKey={"fintechUnplanned"}
          />
        </Col>
        <Col>
          <DeploymentCard
            title={"Growth"}
            data={deploymentsData}
            plannedKey={"growthPlanned"}
            unplannedKey={"growthUnplanned"}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
