import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from "recharts";
import { Legend } from "chart.js";

export default function EscalationsStackChart(props) {
  const { data } = props;
  return (
    <ResponsiveContainer minWidth="250px" minHeight="60px">
      <BarChart
        data={data[1]}
        layout="vertical"
        margin={{ top: 5, right: 5, left: 25, bottom: 0 }}
      >
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          dataKey={"name"}
          type="category"
          axisLine={false}
          tickLine={false}
        />
        <Bar
          dataKey="created"
          fill="red"
          strokeWidth={2}
          label={{ fill: "white", fontSize: 14 }}
        />
        <Bar
          dataKey="resolved"
          fill="#3ac47d"
          strokeWidth={2}
          label={{ fill: "white", fontSize: 14 }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
