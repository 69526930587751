import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import EscalationsHistoryCard from "./cards/EscalationsHistoryCard";
import EscalationsSquadsCard from "./cards/EscalationsSquadsCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function EscalationsMetricSection() {
  const { data } = useSelector((state) => state.metrics);

  let coreEscalationsData = [];
  let coreEscalationsSquadData = {};
  let fintechEscalationsData = [];
  let fintechEscalationsSquadData = {};
  let growthEscalationsData = [];
  let growthEscalationsSquadData = {};
  if (data !== undefined && data.escalations) {
    coreEscalationsData = data.escalations.core.data;
    coreEscalationsSquadData = data.escalations.core.squadData;
    fintechEscalationsData = data.escalations.fintech.data;
    fintechEscalationsSquadData = data.escalations.fintech.squadData;
    growthEscalationsData = data.escalations.growth.data;
    growthEscalationsSquadData = data.escalations.growth.squadData;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Escalations over the last 15 days"}
        subtitle={
          "All created and resolved escalations for charters from the last sprint"
        }
        target={
          "Resolved Issues are greater than Created Issues (More green is better)"
        }
      />
      <Row>
        <Col md={6}>
          <EscalationsHistoryCard
            data={coreEscalationsData}
            title={"Core Escalations"}
          />
        </Col>
        <Col md={6}>
          <EscalationsSquadsCard
            data={coreEscalationsSquadData}
            title={"Core Escalations By Squad"}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <EscalationsHistoryCard
            data={fintechEscalationsData}
            title={"Fintech Escalations"}
          />
        </Col>
        <Col md={6}>
          <EscalationsSquadsCard
            data={fintechEscalationsSquadData}
            title={"Fintech Escalations By Squad"}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <EscalationsHistoryCard
            data={growthEscalationsData}
            title={"Growth Escalations"}
          />
        </Col>
        <Col md={6}>
          <EscalationsSquadsCard
            data={growthEscalationsSquadData}
            title={"Growth Escalations By Squad"}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
