import { ResponsiveContainer } from "recharts";
import { Col, Row } from "react-bootstrap";
import StatCard from "./StatCard";

export default function RedFlagStatsCard(props) {
  const { data, title } = props;
  let redFlagStats = {
    name: "dummy",
    escalated: 0,
    reviewed: 0,
    touchedPercentage: 0,
    addNotes: 0,
    requestedDetails: 0,
    loweredPriority: 0,
    changedComponent: 0,
    resolved: 0,
  };
  if (data && data.length > 0) {
    redFlagStats = data[data.length - 1];
  }
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">{title}</div>
        <br />
        <Row>
          <Col>
            <h4>Total Escalations: {redFlagStats.escalated}</h4>
          </Col>
        </Row>
        <div className="widget-chart-wrapper chart-wrapper-relative">
          <ResponsiveContainer height={300}>
            <div className={"red-flag-stats-card-box"}>
              <Row className={"red-flag-stats-card-row"}>
                <Col className={"red-flag-stats-card-col"}>
                  <StatCard title={"Reviewed"} value={redFlagStats.reviewed} />
                </Col>
                <Col className={"red-flag-stats-card-col"}>
                  <StatCard
                    title={"Added Notes"}
                    value={redFlagStats.addNotes}
                  />
                </Col>
                <Col className={"red-flag-stats-card-col"}>
                  <StatCard
                    title={"Requested Details"}
                    value={redFlagStats.requestedDetails}
                  />
                </Col>
              </Row>
              <Row className={"red-flag-stats-card-row"}>
                <Col className={"red-flag-stats-card-col"}>
                  <StatCard
                    title={"Lowered Priority"}
                    value={redFlagStats.loweredPriority}
                  />
                </Col>
                <Col className={"red-flag-stats-card-col"}>
                  <StatCard
                    title={"Re-routed"}
                    value={redFlagStats.changedComponent}
                  />
                </Col>
                <Col className={"red-flag-stats-card-col"}>
                  <StatCard title={"Solved"} value={redFlagStats.resolved} />
                </Col>
              </Row>
            </div>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
