import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import ReleaseDefectCard from "./cards/ReleaseDefectCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function ReleaseDefectsSection() {
  const { data } = useSelector((state) => state.metrics);

  let releaseDefectsData = [];
  if (data !== undefined && data.releaseDefects) {
    releaseDefectsData = data.releaseDefects.data;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Change Failure Rate & Release Defect Tracking"}
        subtitle={
          "Percentage of escalations introduced in a recent release on a 30-day rolling basis of issues resolved"
        }
        target={
          "Less than 5% of fixes should be introduced in the current release timeframe"
        }
      />
      <Row>
        <Col>
          <ReleaseDefectCard
            title={"DPOS"}
            data={releaseDefectsData}
            legacyKey={"dposLegacy"}
            mediumKey={"dposMedium"}
            releaseKey={"dposRelease"}
          />
        </Col>
        <Col>
          <ReleaseDefectCard
            title={"Register"}
            data={releaseDefectsData}
            legacyKey={"registerLegacy"}
            mediumKey={"registerMedium"}
            releaseKey={"registerRelease"}
          />
        </Col>
        <Col>
          <ReleaseDefectCard
            title={"Greenbits"}
            data={releaseDefectsData}
            legacyKey={"gbLegacy"}
            mediumKey={"gbMedium"}
            releaseKey={"gbRelease"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReleaseDefectCard
            title={"Fintech"}
            data={releaseDefectsData}
            legacyKey={"fintechLegacy"}
            mediumKey={"fintechMedium"}
            releaseKey={"fintechRelease"}
          />
        </Col>
        <Col>
          <ReleaseDefectCard
            title={"Growth"}
            data={releaseDefectsData}
            legacyKey={"growthLegacy"}
            mediumKey={"growthMedium"}
            releaseKey={"growthRelease"}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
