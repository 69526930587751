import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

export default function EscalationsPerEngCard(props) {
  const { data, engCount, title, createdKey, resolvedKey } = props;

  let engCreatedAverage = 0;
  let engResolvedAverage = 0;
  if (data && data.length > 0) {
    const lastCreatedValue = data[data.length - 1][createdKey];
    const lastResolvedValue = data[data.length - 1][resolvedKey];
    engCreatedAverage = (lastCreatedValue / engCount).toFixed(2);
    engResolvedAverage = (lastResolvedValue / engCount).toFixed(2);
  }
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">
          Escalations per Engineer per Sprint
        </div>
        <div className="widget-numbers">
          {engCreatedAverage} created, {engResolvedAverage} resolved
        </div>
        <div className="widget-subheading">{title}</div>
      </div>
      <div className="widget-chart-wrapper chart-wrapper-relative">
        <ResponsiveContainer height={100}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 15, left: 15, bottom: 0 }}
          >
            <Tooltip />
            <YAxis />
            <XAxis hide={true} />
            <Line
              type="monotone"
              dataKey={createdKey}
              stroke="red"
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey={resolvedKey}
              stroke="#3ac47d"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
        <Row>
          <Col>
            <div className={"text-danger"}>
              <FontAwesomeIcon icon={faSquare} />
              &nbsp; Created Escalations
            </div>
          </Col>
          <Col>
            <div className={"text-success"}>
              <FontAwesomeIcon icon={faSquare} />
              &nbsp; Resolved Escalations
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
