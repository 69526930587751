import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import ReleaseDefectCard from "./cards/ReleaseDefectCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";
import RedFlagStatsCard from "./cards/RedFlagStatsCard";
import RedFlagEscalationsCard from "./cards/RedFlagEscalationsCard";

export default function RedFlagSection() {
  const { data } = useSelector((state) => state.metrics);

  let redFlagData = [];
  if (data !== undefined && data.redflag) {
    redFlagData = data.redflag.data;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Red Flag Team Metrics"}
        subtitle={"Sprintly review of escalations managed by the Red Flag Team"}
      />
      <Row>
        <Col>
          <RedFlagEscalationsCard
            title={"Red Flag Escaltion Review/Resolved"}
            data={redFlagData}
          />
        </Col>
        <Col>
          <RedFlagStatsCard title={"Sprint Stats"} data={redFlagData} />
        </Col>
      </Row>
    </Fragment>
  );
}
