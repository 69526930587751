import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

export default function RedFlagEscalationsCard(props) {
  const { data, title } = props;

  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">{title}</div>
        <br />
        <div className="widget-chart-wrapper chart-wrapper-relative">
          <ResponsiveContainer height={300}>
            <BarChart data={data} sta>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" xAxisId={0} />
              <XAxis dataKey="name" xAxisId={1} hide />
              <XAxis dataKey="name" xAxisId={2} hide />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="escalated"
                fill="#B0D0D2"
                xAxisId={0}
                barSize={45}
              />
              <Bar dataKey="reviewed" fill="#7E98C9" xAxisId={1} barSize={30} />
              <Bar dataKey="resolved" fill="#CDF5E0" xAxisId={2} barSize={20} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
