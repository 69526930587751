import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

export default function NPSCard(props) {
  const { data, title, dataKey } = props;

  let latestValue = 0;
  let deltaValue = 0;
  let deltaString = "";
  let deltaIcon = faAngleUp;
  let deltaCSS = "text-success";

  if (data && data.length > 0) {
    latestValue = data[data.length - 1][dataKey];
    deltaValue =
      (data.length > 2 ? data[data.length - 2][dataKey] : 0) - latestValue;
    deltaValue = -1 * deltaValue;
    deltaString = deltaValue > -1 ? "+" + deltaValue : deltaValue;
    deltaIcon = deltaValue > -1 ? faAngleUp : faAngleDown;
    deltaCSS = deltaValue > -1 ? "text-success" : "text-danger";
  }
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="icon-wrapper rounded-circle">
          <div className="icon-wrapper-bg bg-success" />
          <i className="lnr-heart text-success" />
        </div>
        <div className="widget-numbers">{latestValue}</div>
        <div className="widget-subheading">{title}</div>
        <div className={`widget-description ${deltaCSS}`}>
          <span className="pe-1">{deltaString}</span>
          <FontAwesomeIcon icon={deltaIcon} />
        </div>
      </div>
      <div className="widget-chart-wrapper chart-wrapper-relative">
        <ResponsiveContainer width="100%" aspect={10.0 / 1.0}>
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Area
              type="monotoneX"
              dataKey={dataKey}
              stroke="#5C1AE8"
              fill="#DFD1FA"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
