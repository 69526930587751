import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import LeadTimeToChangeCard from "./cards/LeadTimeToChangeCard";
import { Fragment } from "react";
import SectionHeader from "../components/SectionHeader";

export default function LeadTimeToChangeSection() {
  const { data } = useSelector((state) => state.metrics);

  let leadTimeData = [];
  if (data !== undefined && data.leadTimeToChange) {
    leadTimeData = data.leadTimeToChange.data;
  }

  return (
    <Fragment>
      <SectionHeader
        title={"Lead Time to Change"}
        subtitle={
          "Tracks time from creation to Done, pulling in any item that has started progress in the last 30 days."
        }
        target={
          "Time should trend down as older issues are resolved from the backlog."
        }
      />
      <Row>
        <Col>
          <LeadTimeToChangeCard
            title={"DPOS"}
            dataKey={"dpos"}
            data={leadTimeData}
          />
        </Col>
        <Col>
          <LeadTimeToChangeCard
            title={"Register"}
            dataKey={"register"}
            data={leadTimeData}
          />
        </Col>
        <Col>
          <LeadTimeToChangeCard
            title={"Greenbits"}
            dataKey={"gb"}
            data={leadTimeData}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LeadTimeToChangeCard
            title={"Fintech"}
            dataKey={"fintech"}
            data={leadTimeData}
          />
        </Col>
        <Col>
          <LeadTimeToChangeCard
            title={"Growth"}
            dataKey={"growth"}
            data={leadTimeData}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
