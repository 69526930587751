import { Col, Row } from "react-bootstrap";
import EscalationsStackChart from "../widgets/EscalationsStackChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

export default function EscalationsSquadsCard(props) {
  const { data, title } = props;
  const squads = Object.entries(data);
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="widget-heading">{title}</div>
        <br />
        <div className="widget-chart-wrapper chart-wrapper-relative">
          <Row>
            {squads.map((squad) => {
              return (
                <Col sm={6}>
                  <EscalationsStackChart data={squad} key={squad.name} />
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <div className={"text-danger"}>
                <FontAwesomeIcon icon={faSquare} />
                &nbsp; Created Escalations
              </div>
            </Col>
            <Col>
              <div className={"text-success"}>
                <FontAwesomeIcon icon={faSquare} />
                &nbsp; Resolved Escalations
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
