import { Fragment, useEffect } from "react";
import NPSMetricSection from "./metrics/NPSMetricSection";
import { useDispatch } from "react-redux";
import { fetchMetrics } from "./reducers/metricsSlice";
import EscalationsMetricSection from "./metrics/EscalationsMetricSection";
import EscalationsPerEngSection from "./metrics/EscalationsPerEngSection";
import DeploymentFrequencySection from "./metrics/DeploymentFrequencySection";
import LeadTimeToChangeSection from "./metrics/LeadTimeToChangeSection";
import IncidentsSection from "./metrics/IncidentsSection";
import FlowDistributionSection from "./metrics/FlowDistributionSection";
import ReleaseDefectsSection from "./metrics/ReleaseDefectsSection";
import { Navbar, NavbarBrand } from "react-bootstrap";
import RedFlagSection from "./metrics/RedFlagSection";
import EndToEndTestsSection from "./metrics/EndToEndTestsSection";

export default function JirlinDashboard() {
  //const { status } = useSelector((state) => state.metrics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMetrics());
  }, []);

  return (
    <Fragment>
      <Navbar bg="light" variant="light" sticky="top">
        <NavbarBrand>Frank's Quality Metrics</NavbarBrand>
      </Navbar>
      <EscalationsMetricSection />
      <EscalationsPerEngSection />
      <DeploymentFrequencySection />
      <LeadTimeToChangeSection />
      <IncidentsSection />
      <ReleaseDefectsSection />
      <FlowDistributionSection />
      <NPSMetricSection />
      <RedFlagSection />
      <EndToEndTestsSection />
    </Fragment>
  );
}
