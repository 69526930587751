import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default function IncidentsCard(props) {
  const { data, mttmKey, mttrKey, incidentsKey, title } = props;

  let mttm = 0;
  let mttr = 0;
  let incidents = 0;
  let latestValue = {};
  let deltaValue = 0;
  let deltaIcon = faAngleUp;
  let deltaCSS = "text-success";
  if (data && data.length > 0) {
    latestValue = data[data.length - 1];
    mttm = latestValue[mttmKey];
    mttr = latestValue[mttrKey];
    incidents = latestValue[incidentsKey];
    deltaValue =
      (data.length > 2 ? data[data.length - 2][incidentsKey] : 0) - latestValue;
    deltaValue = -1 * deltaValue;
    deltaIcon = deltaValue > -1 ? faAngleDown : faAngleUp;
    deltaCSS = deltaValue > -1 ? "text-success" : "text-danger";
  }
  return (
    <div className="card mb-3 widget-chart">
      <div className="widget-chart-content">
        <div className="icon-wrapper rounded-circle">
          <div className="icon-wrapper-bg bg-success" />
          <i className="lnr-heart-pulse text-success" />
        </div>

        <div className="widget-numbers">{mttm} Hours (MTTM)</div>
        <div className="widget-numbers">{mttr} hours (MTTR)</div>
        <div className="widget-subheading">{title}</div>
        <div className="widget-heading">Mean Time to Recovery</div>

        <div className={`widget-description ${deltaCSS}`}>
          <FontAwesomeIcon icon={deltaIcon} />
          <span className="ps-1">{incidents} incidents</span>
        </div>
      </div>
    </div>
  );
}
